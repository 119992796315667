import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G01Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g01Guid
      this.g01Guid = props.g01Guid
      this.g11Guid = props.g11Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.meterageListGuid = props.meterageListGuid
      this.currentMonthNum = props.currentMonthNum
      this.currentMonthAmount = props.currentMonthAmount
      this.currentMonthPercent = props.currentMonthPercent
      this.totalMonthNum = props.totalMonthNum
      this.totalMonthAmount = props.totalMonthAmount
      this.totalMonthPercent = props.totalMonthPercent
      this.totalNum = props.totalNum
      this.totalAmount = props.totalAmount
      this.totalPercent = props.totalPercent
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
      this.year = props.year
      this.month = props.month
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g01Guid) this.g01Guid = ''
    if (!this.g11Guid) this.g11Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.meterageListGuid) this.meterageListGuid = ''
    if (!this.currentMonthNum) this.currentMonthNum = 0
    if (!this.currentMonthAmount) this.currentMonthAmount = 0
    if (!this.currentMonthPercent) this.currentMonthPercent = 0
    if (!this.totalMonthNum) this.totalMonthNum = 0
    if (!this.totalMonthAmount) this.totalMonthAmount = 0
    if (!this.totalMonthPercent) this.totalMonthPercent = 0
    if (!this.totalNum) this.totalNum = 0
    if (!this.totalAmount) this.totalAmount = 0
    if (!this.totalPercent) this.totalPercent = 0
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.year) this.year = 0
    if (!this.month) this.month = 0
  }

  generatePrimaryKey () {
    this.g01Guid = utility.getUuid()
    this.resourceId = this.g01Guid
  }
}
